import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Missing } from "../AdminTable.tsx";
import { adminUserLink } from "../itemLinks.ts";

export function UserLink({ value }: { value: string | number | null | undefined }) {
    if (value == null) {
        return <Missing />;
    }

    return <AdminLinkButton action={adminUserLink(String(value))} label={value} />;
}
